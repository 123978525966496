<template>
	<div>
		<Alert :message="error" v-if="error" />
		<div class="modal" v-if="currentUser || admin || userManagesEmployee">
			<div class="modal-container">
				<div class="modal-content tile">
					<form
						class="rm-form"
						id="update-profile"
						@submit.prevent="updateProfile"
					>
						<div class="modal-header">
							<div class="left">
								<div class="modal-title">
									<h3>Update Employee Profile</h3>
								</div>
							</div>
							<div class="right">
								<div class="btn btn-icon" @click="back">
									<div class="text">
										<p>Close</p>
									</div>
									<fa class="icon" icon="times" />
								</div>
							</div>
						</div>
						<div class="modal-body">
							<div id="preview">
								<img v-if="preview" :src="preview" />
							</div>
							<div class="form-input">
								<label>Update Profile Image:</label>
								<input
									type="file"
									class="custom-file-input-img"
									@change="onFileChange"
								/>
							</div>
							<div class="error-text" v-if="fileError">
								{{ fileError }}
							</div>

							<!-- Anyone with access can edit -->
							<div class="form-input">
								<label for="fullName">Name:</label>
								<input type="text" v-model="fullName" />
							</div>

							<!-- Anyone with access can edit -->
							<div class="form-input">
								<label for="fullName">Title:</label>
								<input type="text" v-model="jobTitle" />
							</div>

							<!-- Admin only and can't change role of self -->
							<div class="form-input" v-if="admin">
								<label for>Role:</label>
								<vue-select
									:disabled="owner"
									v-model="role"
									:options="options"
									close-on-select
									:min="1"
								/>
							</div>

							<!-- Nobody can edit -->
							<!-- <div class="form-input">
                                <label for="fullName">email:</label>
                                <input type="text" disabled :value="email" />
                            </div>-->

							<!-- Admin only and can't suspend owner -->
							<div class="form-checkbox" v-if="admin">
								<label class="checkbox-container">
									<span class="text" v-if="isActive"
										>Account Active</span
									>
									<span class="text" v-else
										>Account Suspended</span
									>
									<input
										type="checkbox"
										:disabled="owner"
										v-model="isActive"
									/>
									<span class="check"></span>
								</label>
							</div>

							<div class="form-messages" v-if="success">
								<p>User updated!</p>
							</div>

							<div class="form-submit" v-if="hasCompanyData">
								<div class="spinner-container" v-if="updating">
									<div class="rm-spinner"></div>
								</div>

								<button
									class="btn btn-primary"
									v-if="!updating"
								>
									Update profile
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="modal" v-else>
			<div class="modal-container">
				<div class="modal-content tile">
					<form
						class="rm-form"
						id="update-profile"
						disabled
						@submit.prevent="updateProfile"
					>
						<div class="modal-header">
							<div class="left">
								<div class="modal-title">
									<h3>Update Employee Profile</h3>
								</div>
							</div>
							<div class="right">
								<div class="btn btn-icon" @click="back">
									<div class="text">
										<p>Close</p>
									</div>
									<fa class="icon" icon="times" />
								</div>
							</div>
						</div>
						<div class="modal-body">
							<p>
								<b>Access denied</b>
							</p>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- Block User for Role Upgrade -->
		<div class="alert" v-if="showAlertForUpgradingRole" @click="close">
			<span
				>Please Upgrade your subscription to add more contributors</span
			>

			<router-link
				v-if="admin"
				class="btn btn-success"
				:to="{
					name: 'Upgrade',
				}"
				>Upgrade</router-link
			>
			<button v-else class="btn btn-success" @click="close">Close</button>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import getDocumentOnce from "@/composables/getDocumentOnce";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import Alert from "@/components/Alert";
import { projectAuth, projectFirestore } from "../../firebase/config";
import getDocument from "../../composables/getDocument";
import { useRouter, useRoute } from "vue-router";
import account from "../../composables/account";

export default {
	name: "UpdateProfileForm",
	components: { Alert },
	setup() {
		const router = useRouter();
		const route = useRoute();
		const error = ref(null);
		const { userDoc, companyId } = account();
		let employeeId = route.query.id;
		let userManagesEmployee = route.query.manages;

		const profile = ref(null);
		const user = projectAuth.currentUser;

		// image upload and preview
		const types = ["image/png", "image/jpeg"];
		const file = ref(null);
		const preview = ref(null);
		const fileError = ref(null);

		// form refs
		const profileImage = ref("");
		const fullName = ref("");
		const jobTitle = ref("");
		const email = ref("");
		const role = ref("");
		const options = ["Admin", "Contributor", "Employee"];
		const isOwner = ref(null);
		const isActive = ref(null);
		const updating = ref(false);
		const success = ref(false);
		const showAlertForUpgradingRole = ref(false);
		var previousRole = "";

		// Data
		let collectionRef = projectFirestore
			.collection("companies")
			.doc(companyId)
			.collection("employees");

		let companyDoc = projectFirestore
			.collection("companies")
			.doc(companyId);

		let usersRef = projectFirestore.collection("users").doc(employeeId);

		const {
			error: useStorageErr,
			downloadUrl,
			uploadProfileImg,
		} = useStorage();

		let employeeRef = collectionRef.doc(employeeId);
		const { error: useDocErr, updateDoc } = useDocument(employeeRef);
		const { document: companyData } = getDocument(companyDoc);

		const { updateDoc: companyDataUpdate } = useDocument(companyDoc);

		onMounted(async () => {
			// get the employee doc once to pre-fill the form
			const { document: employeeDoc } = await getDocumentOnce(
				employeeRef
			);

			if (employeeDoc.value) {
				// Fill the form
				preview.value = employeeDoc.value.profile.profileImage;
				profileImage.value = employeeDoc.value.profile.profileImage;
				fullName.value = employeeDoc.value.profile.fullName;
				jobTitle.value = employeeDoc.value.profile.jobTitle;
				role.value = employeeDoc.value.role;
				previousRole = employeeDoc.value.role;
				isActive.value = employeeDoc.value.isActive;
				email.value = employeeDoc.value.profile.email;
				isOwner.value = employeeDoc.value.owner;
			}
		});

		const isRoleDegrading = () => {
			return role.value === "Employee";
		};

		const updateRole = () => {
			if (!isRoleUpdating()) return;

			let contributors =
				companyData.value.subscription.used_contributors ?? 1;
			let uids = companyData.value.subscription.uids ?? [
				companyData.value.ownerUid,
			];

			if (isRoleUpgrading()) {
				// Increase the count of used_contributors
				uids.push(employeeId);
				companyDataUpdate({
					subscription: {
						...companyData.value.subscription,
						used_contributors: contributors + 1,
						uids: uids,
					},
				});
			} else if (isRoleDegrading()) {
				// No problem, decrease the count of used_contributors, once it exists
				const index = uids.indexOf(employeeId);
				if (index > -1) {
					uids.splice(index, 1);
				}

				companyDataUpdate({
					subscription: {
						...companyData.value.subscription,
						used_contributors: contributors - 1,
						uids: uids,
					},
				});
			}
			previousRole = role.value;
		};

		const close = () => {
			showAlertForUpgradingRole.value = false;
		};

		const isRoleUpgrading = () => {
			return (
				previousRole === "Employee" &&
				["Admin", "Contributor"].includes(role.value)
			);
		};

		const isRoleUpdating = () => {
			return role.value !== previousRole;
		};

		const updateProfile = async () => {
			error.value = null;
			success.value = false;
			updating.value = true;

			// If role is changed and can not add more, show pop up
			console.log(previousRole, "->", role.value);
			if (
				isRoleUpdating() &&
				isRoleUpgrading() &&
				!canAddContributor.value
			) {
				console.log("block");
				role.value = previousRole;
				showAlertForUpgradingRole.value = true;
				updating.value = false;
				return;
			}

			if (file.value) {
				await uploadProfileImg(file.value);

				if (useStorageErr.value) {
					error.value = "Could not upload image";
					return;
				}

				let profile = {
					profile: {
						profileImage: downloadUrl.value,
						fullName: fullName.value,
						jobTitle: jobTitle.value,
						email: email.value,
					},
					role: role.value,
					isActive: isActive.value,
				};

				await updateDoc(profile);

				if (!useDocErr.value) {
					updateRole();
					if (employeeId === user.uid) {
						usersRef.update({ fullName: fullName.value });
						user.updateProfile({
							displayName: fullName.value,
							photoURL: downloadUrl.value,
						});
					}
				}

				updating.value = false;
				success.value = true;
				setTimeout(() => {
					success.value = false;
				}, 2000);
			} else {
				let profile = {
					profile: {
						fullName: fullName.value,
						jobTitle: jobTitle.value,
						email: email.value,
					},
					role: role.value,
					isActive: isActive.value,
				};
				await updateDoc(profile);
				usersRef.update({ fullName: fullName.value });

				if (!useDocErr.value) {
					updateRole();
					if (employeeId === user.uid) {
						await user.updateProfile({
							displayName: fullName.value,
						});
					}
				}

				updating.value = false;
				success.value = true;
				setTimeout(() => {
					success.value = false;
				}, 2000);
			}
		};

		const onFileChange = (e) => {
			const selected = e.target.files[0];

			if (
				selected &&
				types.includes(selected.type) &&
				selected.size < 1000000
			) {
				file.value = selected;
				preview.value = URL.createObjectURL(selected);
				fileError.value = null;
			} else {
				file.value = null;
				fileError.value = "Image must be png/jpg and less than 1mb";
			}
		};

		const admin = computed(() => {
			if (userDoc.value) {
				return userDoc.value.role == "Admin";
			}
		});

		const owner = computed(() => {
			return isOwner.value;
		});

		const currentUser = computed(() => {
			if (employeeId == user.uid) {
				return true;
			}
		});

		const hasCompanyData = computed(() => {
			return companyData.value !== null;
		});

		const canAddContributor = computed(() => {
			console.log("max", companyData.value.subscription.max_contributors);
			console.log(
				"used",
				companyData.value.subscription.used_contributors
			);
			return (
				companyData.value.subscription.max_contributors >
				(companyData.value.subscription.used_contributors ?? 1)
			);
		});

		const back = () => {
			router.go(-1);
		};

		return {
			error,
			profile,
			onFileChange,
			fileError,
			preview,
			updateProfile,
			fullName,
			jobTitle,
			email,
			role,
			isActive,
			updating,
			options,
			owner,
			admin,
			success,
			currentUser,
			hasCompanyData,
			close,
			showAlertForUpgradingRole,
			companyData,
			back,
			userManagesEmployee,
		};
	},
};
</script>

<style lang="scss" scoped>
#profileImage {
	width: 80px;
	height: 80px;
	margin: 0 auto;
}

.form-checkbox {
	display: flex;
	justify-content: flex-start;
	padding: 0.7rem 0 0;
}

#preview {
	width: 80px;
	height: 80px;
}

.right {
	font-size: 14px;
	@include respond(small) {
		font-size: 15px;
	}
}
// .alert {
//     position: fixed;
//     bottom: 1em;
//     right: 1em;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     gap: 1em;
//     background-color: white;
//     border-radius: 8px;
//     box-shadow: $box-shadow;
//     padding: 12px 25px;
//     color: $rm-dark-blue;
//     z-index: 9999;
//     .btn-primary {
//         background-color: $rm-danger;
//     }
// }
.rm-form {
	max-width: 80vw;
	max-height: 90vh;
	overflow: auto;

	@include respond(large) {
		max-height: 95vh;
	}
}
</style>
