<template>
    <Alert :message="error" v-if="error" />
    <div class="right-content">
        <PeopleDashNav :access="access" />
        <div class="page-content">
            <sub-nav-loading v-if="!employees" />
            <div class="list-container no-sub-nav">
                <!-- Loading -->
                <list-loading v-if="!employees" />

                <!-- empty list -->
                <div class="no-list vertical-center" v-if="employees && employees.length == 0">
                    <div class="icon">
                        <fa icon="user" />
                    </div>
                    <p>No people found</p>
                </div>

                <!-- documents list -->
                <div v-if="employees" class="table-row-container">
                    <div class="empty" v-if="empty">No suspended employees found</div>
                    <div v-for="employee in employees" :key="employee.id">
                        <router-link
                            :to="{ name: 'Profile', params: { id: employee.id }}"
                            class="row-link"
                        >
                            <!-- document row -->
                            <div class="table-row">
                                <div class="row-left">
                                    <div class="circle-avatar">
                                        <img :src="employee.profile.profileImage" />
                                    </div>
                                    <div class="title">{{ employee.profile.fullName }}</div>
                                    <div class="description">{{ employee.profile.jobTitle }}</div>
                                </div>
                                <div class="row-right">
                                    <p>{{ employee.role }}</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Alert from "../../components/Alert";
import PeopleDashNav from "@/components/people/PeopleDashNav.vue";
import PageLoading from "../../components/PageLoading";
import getCollection from "../../composables/getCollection";
import { projectFirestore } from "../../firebase/config";
import account from "../../composables/account";
import { ref } from "@vue/reactivity";
import { computed, watchEffect } from "@vue/runtime-core";
import { useMeta } from "vue-meta";
import ListLoading from "../../components/ListLoading.vue";
import SubNavLoading from "../../components/SubNavLoading.vue";

export default {
    name: "SuspendedDash",
    components: {
        PeopleDashNav,
        PageLoading,
        Alert,
        ListLoading,
        SubNavLoading,
    },
    setup() {
        useMeta({ title: "Suspended Users" });
        const error = ref(null);
        const { companyId, userDoc } = account();

        const collectionRef = projectFirestore
            .collection("companies")
            .doc(companyId)
            .collection("employees")
            .where("isActive", "==", false)
            .orderBy("createdAt", "asc");
        const { error: employeesErr, documents: employees } =
            getCollection(collectionRef);

        const empty = computed(() => {
            return employees.value.length == 0;
        });

        // Access
        const access = computed(() => {
            if (userDoc.value) {
                return (
                    userDoc.value.role == "Admin" ||
                    userDoc.value.manages.length > 0
                );
            }
        });

        // handle errors
        watchEffect(() => {
            if (employeesErr.value) {
                error.value = "Cannot fetch employees";
            } else {
                error.value = null;
            }
        });

        return { error, employees, empty, access };
    },
};
</script>

<style lang="scss" scoped>
 .empty{
    @include respond(large){
        padding-left: 1.2em;
    }
 }
 .no-list{
    @include respond(large){
        padding-left: 1.2em;
    }
 }
</style>