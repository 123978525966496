<template>
	<div class="modal">
		<div class="modal-container">
			<div class="modal-content tile">
				<form class="rm-form" @submit.prevent="handleSubmit">
					<div class="modal-header">
						<h3>Invite an employee</h3>
						<router-link
							class="close"
							:to="{ name: 'InvitationsDash' }"
						>
							<fa icon="times" />
						</router-link>
					</div>
					<div class="modal-body">
						<div class="form-input">
							<label>Employee Email Address:</label>
							<input type="email" required v-model="email" />
							<div
								class="error-text"
								v-if="duplicateEmail && !success && !isLoading"
							>
								{{ emailExists }}
							</div>
						</div>

						<div class="form-input">
							<label>Select Their Location:</label>
							<vue-select
								v-model="location"
								:options="locations"
								label-by="locationName"
								placeholder="Select a location"
								close-on-select
								:min="1"
								searchable
								search-placeholder="Search & add a location"
							></vue-select>
						</div>

						<div class="form-messages" v-if="!disable">
							<div class="error-text">
								You must have an Admin or Manager account to
								invite users
							</div>
						</div>
						<div class="form-messages" v-if="error">
							<div class="error-text">
								{{ error }}
							</div>
						</div>
						<div class="form-messages" v-if="success">
							Invitation sent!
						</div>
						<div class="form-submit">
							<page-loading v-if="isLoading" />
							<button
								class="btn btn-primary"
								v-else
								:disabled="
									!disable ||
									duplicateEmail ||
									location == null ||
									email == null
								"
							>
								Send Invitation
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { projectFirestore, timestamp } from "../../firebase/config";
import { computed, onMounted } from "@vue/runtime-core";
import useCollection from "../../composables/useCollection";
import account from "../../composables/account";
import getDocument from "../../composables/getDocument";
import getDocumentOnce from "../../composables/getDocumentOnce";
import getCollection from "../../composables/getCollection";
import PageLoading from "../../components/PageLoading.vue";

export default {
	components: { PageLoading },
	name: "InviteModal",
	setup() {
		const { userDoc, companyId, user } = account();
		const router = useRouter();
		const error = ref(null);
		const isLoading = ref(false);
		const location = ref();
		const locations = ref([]);
		const invitations = ref([]);
		const success = ref(false);

		let db = projectFirestore;

		// form refs
		const email = ref(null);
		const emailExists = ref("");

		const companyRef = db.collection("companies").doc(companyId);
		const { document: companyDoc } = getDocument(companyRef);

		const invitationsRef = db.collection("invitations");
		const { documents } = getCollection(invitationsRef);

		onMounted(async () => {
			const { document } = await getDocumentOnce(companyRef);
			locations.value = document.value.locations;
		});

		const disable = computed(() => {
			if (userDoc.value) {
				return (
					userDoc.value.role == "Admin" ||
					userDoc.value.manages.length > 0
				);
			}
		});

		const duplicateEmail = computed(() => {
			// Cannot invite yourself
			if (user.value.email == email.value) {
				emailExists.value = "You cannot invite yourself";
				return true;
			}

			if (documents.value) {
				invitations.value = documents.value;

				let emails = [];

				invitations.value.forEach((invitation) => {
					emails.push(invitation.employeeEmail);
				});

				if (!emails.includes(email.value)) {
					emailExists.value = null;
					return false;
				} else {
					emailExists.value = "This email has an existing invitation";
					return true;
				}
			}
		});

		const handleSubmit = async () => {
			error.value = null;
			isLoading.value = true;

			if (companyDoc.value.subscription.status == "canceled") {
				error.value = "Error! Invalid Subscription";
				isLoading.value = false;
				setTimeout(() => {
					error.value = null;
				}, 3000);
				return;
			}

			const userRef = db
				.collection("users")
				.where("email", "==", email.value);

			const querySnapshot = await userRef.get();
			const existingUser = querySnapshot.docs.map((doc) => doc.data());

			if (existingUser.length > 0) {
				error.value = "This email is already registered";
				isLoading.value = false;
				setTimeout(() => {
					error.value = null;
				}, 3000);
				return;
			}

			const invitation = {
				companyName: companyDoc.value.companyName,
				employerName: user.value.displayName,
				employerEmail: user.value.email,
				companyUid: companyId,
				employeeEmail: email.value,
				location: {
					locationId: location.value.locationId,
					locationName: location.value.locationName,
				},
				status: "Pending",
				createdAt: timestamp(),
			};

			// Collection
			let invitationsRef = db.collection("invitations");
			const { addDoc, error: addDocErr } = useCollection(invitationsRef);

			await addDoc(invitation)
				.then(() => {
					isLoading.value = false;
					success.value = true;
					setTimeout(() => {
						router.push({ name: "InvitationsDash" });
					}, 1000);
				})
				.catch((e) => {
					isLoading.value = false;
					error.value = "Could not create invitation";
					console.error(e);
				});
		};

		return {
			error,
			handleSubmit,
			email,
			isLoading,
			disable,
			location,
			locations,
			duplicateEmail,
			emailExists,
			success,
		};
	},
};
</script>

<style lang="scss" scoped></style>
