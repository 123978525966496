<template>
	<div class="modal">
		<div class="modal-container">
			<div class="modal-content tile">
				<div class="modal-header">
					<div class="left">
						<div class="modal-title">
							<h3>Cancel Subscription</h3>
						</div>
					</div>
					<div class="right">
						<router-link
							:to="{
								name: 'Subscription',
							}"
						>
							<fa class="icon" icon="times" />
						</router-link>
					</div>
				</div>
				<div class="modal-body">
					<div class="split">
						<div class="row">
							<div class="section-cancel" v-if="!isCanceled">
								<p>
									<b
										>Here's what happens when you cancel
										your subscription:</b
									>
								</p>
								<br />
								<p>
									First, nothing will change until the end of
									the current billing cycle.
								</p>
								<br />
								<p>
									Once the current billing cycle has ended,
									the app's functionality will be restricted,
									but we will keep your account data safe.
								</p>
								<br />
								<p>
									To reactivate your account, simply purchase
									a new subscription. To revert back to a free
									account, please contact us. To delete your
									account and all data, please contact us.
								</p>
								<div class="spacer-40"></div>
								<button
									class="btn btn-danger"
									@click="cancelSub"
								>
									{{
										isLoading
											? "Cancelling "
											: "Cancel " + "subscription"
									}}
								</button>
							</div>
							<div class="section-cancel" v-else>
								<div class="center">
									<h2>Subscription Cancelled</h2>
								</div>
								<br />
								<p>
									<i
										>Before you go, here's a quick message
										from our CEO...</i
									>
								</p>
								<br />
								<div class="ceo-message">
									<p>First, thank you for giving us a go!</p>
									<br />
									<p>
										We're a small startup trying our best to
										build something useful, so any feedback
										as to why you've cancelled is really
										important to us. If you could spare a
										few minutes to write me a quick feedback
										email, I'd greatly appreciate it.
									</p>
									<br />
									<p>Best,</p>
									<br />
									<p>Alistair Scott, CEO &amp; Co-Founder</p>
								</div>
								<br />
								<br />
								<div class="center">
									<a
										class="btn btn-primary"
										href="mailto:alistair@riskmemo.com?subject=Risk Memo Cancellation Feedback"
									>
										<b>Send feedback email</b>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, ref } from "vue";
import PageLoading from "../PageLoading.vue";
import { useRouter } from "vue-router";
import { projectFunctions } from "@/firebase/config";

export default {
	components: { PageLoading },
	name: "CancelSubscription",

	props: ["subscriptionId"],
	setup(props) {
		// const subscription = toRef(props, "subscription");
		const isLoading = ref(false);
		const isCanceled = ref(false);
		const router = useRouter();

		onMounted(() => {
			if (!props.subscriptionId) {
				router.go(-1);
			}
		});

		const cancelSub = async () => {
			isLoading.value = true;

			const cancelSubscription =
				projectFunctions.httpsCallable("cancelSubscription");
			try {
				const result = await cancelSubscription({
					subId: props.subscriptionId,
				});

				if (result.data.error) {
					console.log(
						"Failed to cancel subscription",
						result.data.error
					);
					return;
				}

				isCanceled.value = true;
				isLoading.value = false;
			} catch (err) {
				console.log("Failed to cancel subscription", err);
				isLoading.value = false;
			}
		};

		return { isLoading, cancelSub, isCanceled };
	},
};
</script>

<style lang="scss" scoped>
// .section {
//     display: flex;
//     flex-direction: column;
//     gap: 1em;
//     padding: 1em;
// }
// .section-cancel {
//     width: 70%;
//     margin: 0 auto;
// }
// .item {
//     margin-bottom: 1em;
// }
// .help-text {
//     color: $rm-grey;
//     // font-size: 0.8em;
// }
.modal-content {
	//  width: 60% !important;
	max-width: 700px !important;
}
.center {
	width: 100%;
	text-align: center;
}
.ceo-message {
	padding: 1em;
	background-color: $rm-off-white;
	border-radius: 8px;
}
</style>
