<template>
	<Alert :message="error" v-if="error" />
	<div class="right-content">
		<PeopleDashNav :access="access" />
		<div class="page-content">
			<sub-nav-loading v-if="!documents" />
			<div v-else class="right-sub-nav fixed" v-if="access">
				<div class="nav-row">
					<div class="sub-nav-left">
						<span class="muted"
							>Used seats: {{ usedSeats }} / {{ seatLimit }}</span
						>
					</div>
					<div class="sub-nav-right">
						<button
							class="btn btn-success"
							@click="goToInviteModal"
							:disabled="limited"
						>
							Invite employee
						</button>
					</div>
				</div>
			</div>

			<div class="list-container">
				<!-- Loading -->
				<list-loading v-if="!documents" />

				<!-- empty list -->
				<div
					class="no-list vertical-center"
					v-if="documents && documents.length == 0"
				>
					<div class="icon">
						<fa icon="envelope-open-text" />
					</div>
					<p>No invitations found</p>
				</div>

				<!-- documents list -->
				<div
					v-if="documents && documents.length > 0"
					class="table-row-container"
				>
					<div v-for="doc in documents" :key="doc.id">
						<!-- document row -->
						<div class="table-row no-link">
							<div class="row-left">
								<div class="title no-img-title">
									{{ doc.employeeEmail }}
								</div>
								<div
									class="description"
									v-if="doc.status == 'Pending'"
								>
									<div
										class="invite"
										@click="
											copyUrl(doc.id), copySuccess(doc.id)
										"
										:class="{ isCopied: copied }"
										v-if="
											copiedId == '' || copiedId == doc.id
										"
									>
										<span id="invite-url"
											>copy invite url</span
										>
										<div class="copy-icon">
											<fa v-if="!copied" icon="clone" />
											<fa v-else icon="check" />
										</div>
									</div>
								</div>
							</div>
							<div class="row-right">
								<div class="help-text">
									<p>{{ doc.status }}</p>
								</div>
								<div
									class="help-text"
									v-if="
										doc.status == 'Pending' && doc.createdAt
									"
								>
									<p>
										Sent
										{{
											moment(
												doc.createdAt.toDate()
											).format("MMMM Do YYYY")
										}}
									</p>
								</div>

								<div
									class="actions"
									v-if="doc.status == 'Pending'"
								>
									<div
										class="actions-icon-btn"
										@click="deleteInvite(doc.id)"
									>
										<fa icon="trash" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<router-view v-slot="{ Component }">
		<transition name="fade" mode="in-out">
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script>
// Components
import PeopleDashNav from "../../components/people/PeopleDashNav";
import PageLoading from "../../components/PageLoading.vue";
import Alert from "@/components/Alert";

// Composables
import account from "../../composables/account";
import getCollection from "../../composables/getCollection";
import useCollection from "@/composables/useCollection";
import { projectFirestore } from "../../firebase/config";
import { useSessionStore } from "../../stores/sessionStore";
import { storeToRefs } from "pinia";

// Vue / Other
import { computed, ref, watchEffect } from "@vue/runtime-core";
import EnvUtils from "@/misc/env.js";
import { useMeta } from "vue-meta";
import moment from "moment";
import ListLoading from "../../components/ListLoading.vue";
import SubNavLoading from "../../components/SubNavLoading.vue";
import { useRouter } from "vue-router";

export default {
	name: "InvitationsDash",
	components: {
		PeopleDashNav,
		Alert,
		PageLoading,
		ListLoading,
		SubNavLoading,
	},
	setup() {
		useMeta({ title: "Invitations" });
		const error = ref(null);
		const { companyId, userDoc } = account();
		const router = useRouter();

		// Data
		const db = projectFirestore;
		const sessionStore = useSessionStore();
		const { settingsDoc, companyDoc, employeeCount } =
			storeToRefs(sessionStore);

		const collectionRef = db
			.collection("invitations")
			.where("companyUid", "==", companyId)
			.orderBy("createdAt", "desc");
		const { error: getErr, documents } = getCollection(collectionRef);

		const goToInviteModal = () => {
			if (!limited.value) {
				router.push({ name: "InviteModal" });
			} else {
				error.value =
					"You have reached the team member limit for your current subscription. Please upgrade.";
			}
		};

		// Delete invitation
		const invitationsRef = db.collection("invitations");
		const { error: deleteErr, deleteDoc } = useCollection(invitationsRef);

		const deleteInvite = async (doc) => {
			await deleteDoc(doc);
		};

		const empty = computed(() => {
			return documents.value.length == 0;
		});

		// Access
		const access = computed(() => {
			if (userDoc.value) {
				return (
					userDoc.value.role == "Admin" ||
					userDoc.value.manages.length > 0
				);
			}
		});

		const copyUrl = (id) => {
			console.log(EnvUtils.PORTAL);
			let url = EnvUtils.PORTAL + "/invitation/" + id;
			navigator.clipboard.writeText(url);
		};

		const copied = ref(false);
		const copiedId = ref("");

		const copySuccess = (id) => {
			copiedId.value = id;
			copied.value = true;
			setTimeout(() => {
				copied.value = false;
				copiedId.value = "";
			}, 1000);
		};

		const limited = ref(false);
		const usedSeats = ref(0);
		const seatLimit = ref(0);

		// handle errors
		watchEffect(() => {
			if (documents.value && settingsDoc.value && companyDoc.value) {
				const currentPendingInvitations = documents.value.filter(
					(doc) => doc.status == "Pending"
				);
				usedSeats.value =
					employeeCount.value + currentPendingInvitations.length;

				let limit;
				if (companyDoc.value.subscription.unlimited) {
					// If subscription is unlimited, always use the paid user limit
					limit =
						companyDoc.value.subscription.max_contributors *
						settingsDoc.value.paidUserLimit;
				} else {
					// Otherwise, check if it's free or trial and apply the corresponding limit
					const isFreeOrTrial = ["free", "trial"].includes(
						companyDoc.value.subscription.status
					);
					limit = isFreeOrTrial
						? settingsDoc.value.freeUserLimit
						: companyDoc.value.subscription.max_contributors *
						  settingsDoc.value.paidUserLimit;
				}
				seatLimit.value = limit;

				limited.value = usedSeats.value >= limit;
			}
			if (getErr.value) {
				error.value = "Cannot fetch invitations";
			} else if (deleteErr.value) {
				error.value = "Could not delete invitation";
			} else {
				error.value = null;
			}
		});

		return {
			documents,
			error,
			// formattedDocuments,
			deleteInvite,
			empty,
			access,
			copyUrl,
			copied,
			copySuccess,
			copiedId,
			moment,
			goToInviteModal,
			limited,
			usedSeats,
			seatLimit,
		};
	},
};
</script>

<style lang="scss" scoped>
.table-row {
	.description {
		.invite {
			display: flex;
			justify-content: flex-end;
			color: $rm-grey;
			align-items: center;
			.copy-icon {
				margin-left: 1em;
			}
			&:hover {
				cursor: pointer;
				color: $rm-pink;
			}
		}
		.isCopied {
			color: $rm-success !important;
			&:hover {
				color: $rm-success !important;
			}
		}
	}
}
.row-left {
	.title {
		width: 125px !important;

		@include respond(medium) {
			width: 135px !important;
		}
		@include respond(large) {
			width: 300px !important;
		}
	}
}
.nav-row {
	@include respond(small) {
		justify-content: flex-start !important;
	}
	@include respond(medium) {
		justify-content: space-between !important;
	}
	@include respond(large) {
		justify-content: flex-start !important;
		padding: 0 1.5em;
	}
}
</style>
