<template>
    <div class="modal">
        <div class="modal-container">
            <div class="modal-content tile">
                <form class="rm-form" @submit.prevent="handleSubmit">
                    <div class="modal-header">
                        <h3>Edit location access</h3>
                        <router-link class="close" :to="{ name: 'InspectorInvitationsDash' }">
                            <fa icon="times" />
                        </router-link>
                    </div>
                    <div class="modal-body">
                        <div class="locations-wrap" v-if="locations">
                            <div v-for="doc in locations" :key="doc.locationId">
                                <div
                                    class="settings-btn"
                                    v-bind:class="{ selected: checkIfLocationSelected(doc.locationId) }"
                                    @click="selectLocation(doc.locationId)"
                                >
                                    {{doc.locationName}}
                                    <fa
                                        class="nav-icon"
                                        v-if="checkIfLocationSelected(doc.locationId)"
                                        icon="check-circle"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="spacer-20"></div>
                        <page-loading v-if="isLoading" />
                        <div class="form-submit" v-if="!isLoading">
                            <button class="btn btn-primary" v-on:click.prevent="submit">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { projectFirestore, timestamp } from "../../firebase/config";
import { computed, onMounted, watchEffect } from "@vue/runtime-core";
import account from "../../composables/account";
import getDocument from "../../composables/getDocument";
import getDocumentOnce from "../../composables/getDocumentOnce";
import PageLoading from "../../components/PageLoading.vue";

export default {
    props: ["id"],
    components: { PageLoading },
    name: "EditInspectorLocation",
    setup(props, context) {
        const { companyId } = account();
        const router = useRouter();
        const error = ref(null);
        const isLoading = ref(false);
        const locations = ref([]);
        const selectedLocations = ref([]);
        const thirdPartyCompanyId = ref("");

        let db = projectFirestore;

        const companyRef = db.collection("companies").doc(companyId);
        // const { document: companyDoc } = getDocument(companyRef);

        const inspectorRef = db
            .collection("companies")
            .doc(companyId)
            .collection("inspector")
            .doc(props.id);

        onMounted(async () => {
            console.log("xx", props.id);
            const { document } = await getDocumentOnce(companyRef);
            locations.value = document.value.locations;
            const { document: inspectordocument } = await getDocumentOnce(
                inspectorRef
            );
            console.log(document);
            console.log(inspectordocument);
            selectedLocations.value = inspectordocument.value.location;
            thirdPartyCompanyId.value = inspectordocument.value.companyId;

            console.log(locations.value, "Locations");
            console.log(selectedLocations.value);
            console.log(thirdPartyCompanyId.value);
        });

        const handleSubmit = async () => {};

        const checkIfLocationSelected = (id) => {
            var index = selectedLocations.value.findIndex(
                (item) => item.locationId == id
            );

            if (index == -1) {
                return false;
            }

            return true;
        };

        const selectLocation = (id) => {
            var index = selectedLocations.value.findIndex(
                (item) => item.locationId == id
            );

            if (index == -1) {
                var findLocation = locations.value.find(
                    (x) => x.locationId == id
                );
                selectedLocations.value.push(findLocation);
            } else {
                var findLocation = selectedLocations.value.find(
                    (x) => x.locationId == id
                );
                selectedLocations.value.pop(findLocation);
            }
        };

        const submit = async () => {
            isLoading.value = true;

            try {
                await inspectorRef.update({
                    location: selectedLocations.value,
                });

                await db
                    .collection("companies")
                    .doc(thirdPartyCompanyId.value)
                    .collection("employees")
                    .doc(props.id)
                    .collection("thirdParty")
                    .doc(companyId)
                    .update({ locations: selectedLocations.value });

                isLoading.value = false;

                router.go(-1);
            } catch (e) {}
        };

        return {
            error,
            handleSubmit,
            isLoading,
            location,
            locations,
            checkIfLocationSelected,
            selectLocation,
            submit,
        };
    },
};
</script>

<style lang="scss" scoped>
.locations-wrap {
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    gap: 1em;
    row-gap: 1em;
}
</style>