import { projectFirestore } from "../firebase/config";
import getCollection from "./getCollection";
import useCookies from './useCookies';
import getDocumentOnce from "./getDocumentOnce";
import account from "./account";
import { ref } from 'vue'
const inspectorInviteCollection = () => {
    const { getCookie } = useCookies();
    const error = ref("")
    const company = ref();
    const inspector = ref();
    const { user, companyId: loggedInCompanyId } = account();

    const getData = async (companyId, id) => {

        // Check that the current logged in user id matches the inspector id
        if (id != user.value.uid) {
            error.value = "You are not authorized to accept this invitation. Please login with the correct account."
            return;
        }

        try {
            // Get inspector invite data
            const companyRef = projectFirestore.collection("companies").doc(companyId);
            const inspectorRef = projectFirestore.collection("companies").doc(companyId).collection("inspector").doc(id);

            const { error: compErr, document } = await getDocumentOnce(
                companyRef
            );

            if (document.value) {
                company.value = document.value;
            }

            if (compErr.value) {
                error.value = compErr.value
                console.error(compErr.value);
            }

            const { error: inspectErr, document: inspectordocument } = await getDocumentOnce(
                inspectorRef
            );

            if (inspectordocument.value) {
                inspector.value = inspectordocument.value
            }
            if (inspectErr.value) {
                error.value = inspectErr.value
                console.error(inspectErr.value);
            }

        }
        catch (err) {
            error.value = err.message
            console.error(err)
        }
    }


    const updateInviteData = async (data, inspectorid, thirdPartyCompanyId) => {
        const companiesRef = projectFirestore.collection("companies");
        try {
            const acceptInviteRef = companiesRef
                .doc(loggedInCompanyId)
                .collection("employees")
                .doc(user.value.uid)
                .collection("thirdParty")
                .doc(thirdPartyCompanyId);

            await acceptInviteRef.set(data)

            const inspectorRef = companiesRef
                .doc(thirdPartyCompanyId)
                .collection("inspector")
                .doc(inspectorid);

            await inspectorRef.update({ status: "Accepted" });
        }
        catch (e) {
            console.error(e);
            error.value = e.message
        }
    }

    return { getData, company, inspector, error, updateInviteData }


}

export default inspectorInviteCollection