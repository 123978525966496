<template>
    <Alert :message="error" v-if="error" />

    <!-- Navigation -->
    <div class="right-sub-nav fixed">
        <div class="nav-row">
            <div class="sub-nav-left">
                <div class="search" v-if="role != 'Employee'">
                    <fa class="search-icon" icon="search" />
                    <input type="search" placeholder="Search Employees..." v-model="search" />
                </div>
            </div>
            <div class="sub-nav-right">
                <div
                    v-if="role == 'Admin'"
                    :class="{'muted': isActive, 'inActive': !isActive}"
                    @click="showInactive"
                >Inactive Employees</div>
            </div>
        </div>
    </div>

    <div class="right-content">
        <PeopleDashNav :access="access" />
        <div class="page-content">
            <!-- Loading shimmer -->
            <div class="list-container" v-if="!employees">
                <div class="table-row-container">
                    <div class="table-row-shimmer shine"></div>
                    <div class="table-row-shimmer shine"></div>
                    <div class="table-row-shimmer shine"></div>
                </div>
            </div>
            <!-- employees list -->
            <div v-else class="list-container">
                <!-- empty list -->
                <div class="no-list vertical-center" v-if="employees && employees.length == 0">
                    <div class="icon">
                        <fa icon="user" />
                    </div>
                    <p
                        v-if="role == 'Employee'"
                    >Sorry, this page is not available to Team Member accounts</p>
                    <p v-else>No people found</p>
                </div>

                <div class="table-row-container" v-if="employees">
                    <div v-for="(employee, index) in employees" :key="employee.id">
                        <router-link
                            :to="{ name: 'Profile', params: { id: employee.id }}"
                            class="row-link"
                        >
                            <!-- document row -->
                            <div :class="{ 'tour_item': index === 0}">
                                <div class="table-row">
                                    <div class="row-left">
                                        <div class="circle-avatar">
                                            <img :src="employee.profile.profileImage" />
                                        </div>
                                        <div class="title">{{ employee.profile.fullName }}</div>
                                        <div class="description">{{ employee.profile.jobTitle }}</div>
                                    </div>
                                    <div class="row-right">
                                        <p>{{ employee.role }}</p>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Alert from "../../components/Alert";
import PeopleDashNav from "@/components/people/PeopleDashNav.vue";
import PageLoading from "../../components/PageLoading";
import getCollection from "../../composables/getCollection";
import { projectFirestore } from "../../firebase/config";
import account from "../../composables/account";
import { computed, ref, watchEffect } from "@vue/runtime-core";
import { useMeta } from "vue-meta";

export default {
    name: "PeopleDash",
    components: {
        PeopleDashNav,
        Alert,
        PageLoading,
    },
    setup() {
        useMeta({ title: "People" });
        const error = ref(null);
        const { user, role, companyId, userDoc } = account();
        const search = ref("");
        const isActive = ref(true);

        const collectionRef = projectFirestore
            .collection("companies")
            .doc(companyId)
            .collection("employees")
            .orderBy("createdAt", "asc");
        const { error: employeesErr, documents } = getCollection(collectionRef);

        // Access
        const access = computed(() => {
            if (userDoc.value) {
                return (
                    userDoc.value.role == "Admin" ||
                    userDoc.value.manages.length > 0
                );
            }
        });

        const filteredemployees = ref([]);

        const employees = computed(() => {
            if (documents.value != null && userDoc.value && role.value) {
                var data = [];

                filteredemployees.value = [];

                documents.value.forEach((el) => {
                    if (el.isActive == isActive.value) {
                        filteredemployees.value.push(el);
                    }
                });

                // If admin show all employees
                if (role.value == "Admin") {
                    data = filteredemployees.value;
                } else {
                    // Only show employees that have a matching location
                    filteredemployees.value.forEach((e) => {
                        e.locations.forEach((l) => {
                            if (userDoc.value.locations.includes(l)) {
                                if (!data.includes(e)) {
                                    data.push(e);
                                }
                            }
                        });
                    });
                }
            }

            // Then by search
            if (data && data.length) {
                data = data.filter((item) => {
                    return (
                        item.profile.fullName
                            .toLowerCase()
                            .indexOf(search.value.toLowerCase()) != -1
                    );
                });
            }
            return data;
        });

        const showInactive = () => {
            if (isActive.value) {
                isActive.value = false;
            } else {
                isActive.value = true;
            }
        };

        // handle errors
        watchEffect(() => {
            if (employeesErr.value) {
                error.value = "Cannot fetch employee details";
            } else {
                error.value = null;
            }
        });

        return {
            error,
            employees,
            access,
            search,
            showInactive,
            isActive,
            role,
        };
    },
};
</script>

<style lang="scss" scoped>
.search {
    input {
     //   width: 350px;
        width: 200px;
        @include respond(large) {
            width: 250px;
        }
        @include respond(larger) {
            width: 350px;
        }
        &::placeholder {
            font-size: 1em;
        }
    }
}
.muted {
    cursor: pointer;
    &:hover {
        color: $rm-pink;
    }
}
.inActive {
    color: $rm-blue;
    font-weight: bold;
    cursor: pointer;
    &:hover {
        color: $rm-pink;
    }
}
.table-row .row-left .title{
    font-size: 12px;
    width: 100px;
    @include respond(small){
        font-size: 14px;
        width: 230px;
    }
    @include respond(medium){
        width: 250px;
    }
    @include respond(large){
        font-size: 1em;
        width: 300px;
    }
}
</style>