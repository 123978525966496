<template>
    <Sidebar />
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'

export default {
    name: 'People',
    components: { Sidebar }
}
</script>