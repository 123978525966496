<template>
	<Alert :message="error" v-if="error" />

	<div class="right-content" v-if="viewChecklists">
		<div class="right-nav">
			<div class="flex">
				<h2>
					<span class="back" @click="handleClick">People</span>
					/
					<span
						class="back"
						v-if="employee"
						@click="showChecklists"
						>{{ employee.profile.fullName }}</span
					>
					/
					<span>Checklists</span>
				</h2>
			</div>
			<div class="nav-links-alt">
				<div class="btn btn-icon" @click="showChecklists">
					<div class="text">Back to employee details</div>
					<fa icon="arrow-right" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="page-detail">
				<ChecklistList
					status="complete"
					:companyId="companyId"
					:peopleId="peopleId"
				/>
			</div>
		</div>
	</div>

	<!-- Content -->
	<div v-if="!viewChecklists" class="right-content">
		<div class="right-nav">
			<div class="flex">
				<h2>
					<span class="back" @click="handleClick">People</span>
					/
					<span v-if="employee">{{ employee.profile.fullName }}</span>
				</h2>
			</div>
		</div>
		<div class="page-content">
			<div class="page-container">
				<div class="split">
					<!-- Col 1 - Profile -->
					<div class="row detail-left">
						<!-- Loading shimmer -->
						<div class="row" v-if="!employee">
							<div class="left-title flex">
								<div class="circle-avatar shine"></div>
								<div class="lines shine"></div>
							</div>

							<div>
								<div class="table-row-shimmer shine"></div>
							</div>
						</div>
						<div v-else>
							<div class="row">
								<div class="left-title flex">
									<div
										class="circle-avatar avatar-inline-left"
									>
										<img
											:src="employee.profile.profileImage"
											v-if="employee.profile.profileImage"
										/>
									</div>
									<h3>{{ employee.profile.fullName }}</h3>
								</div>
							</div>
							<div class="row">
								<!-- Profile -->
								<div class="tile" v-if="profile">
									<div
										class="tile-btn-container"
										v-if="access || userManagesEmployee"
									>
										<div
											class="tile-btn"
											@click="
												showForm(
													employeeId,
													userManagesEmployee
												)
											"
										>
											<div class="btn-icon muted">
												<div class="text">
													edit user
												</div>
												<fa icon="pen" />
											</div>
										</div>
									</div>

									<div class="detail">
										<div class="detail-name">
											Job Title:
										</div>
										<div class="detail-title">
											<b>{{
												employee.profile.jobTitle
											}}</b>
										</div>
									</div>
									<div class="detail">
										<div class="detail-name">Email:</div>
										<div class="detail-title">
											<b v-if="showEmail || access">
												<a
													:href="
														'mailto:' +
														employee.profile.email
													"
												>
													{{
														employee.profile.email
													}}&nbsp;
													<fa icon="paper-plane" />
												</a>
											</b>
											<b v-else>{{ maskedEmail }}</b>
										</div>
									</div>
									<div class="detail">
										<div class="detail-name">Role:</div>
										<div class="detail-title">
											<b>{{ employee.role }}</b>
										</div>
									</div>
									<div class="detail">
										<div class="detail-name">Account:</div>
										<div class="detail-title">
											<b v-if="employee.isActive"
												>Active</b
											>
											<b v-else class="danger"
												>Suspended</b
											>
										</div>
									</div>
								</div>
							</div>
							<!-- Documents -->
							<Documents
								v-if="role == 'Admin' || user.uid == employeeId"
								:companyId="companyId"
								collection="employees"
								:assetId="employeeId"
								:access="access"
								:showAddIcon="access"
							/>
						</div>
					</div>

					<!-- Col 2 -->
					<div class="row">
						<div class="collection-title">
							<h3>Checklists:</h3>
							<div
								class="btn btn-icon"
								@click="showChecklists"
								v-if="access || userManagesEmployee"
							>
								<div class="text">Search checklists</div>
								<fa class="icon" icon="search" />
							</div>
						</div>
						<div class="history-scroll">
							<div class="log-item_container">
								<div
									v-for="checklist in checklists"
									:key="checklist.id"
									v-if="checklists && checklists.length > 0"
								>
									<div class="log-item_tile">
										<div class="user-header">
											<p v-if="checklist.completedAt">
												{{
													moment(
														checklist.completedAt.toDate()
													).format(
														"MMMM Do YYYY, h:mm a"
													)
												}}
											</p>
										</div>
										<div class="message">
											<div class="checklist">
												<div class="check-message">
													<fa
														class="icon"
														icon="tasks"
													/>
													<div>
														<p>
															<b>{{
																checklist.name
															}}</b>
														</p>
														<p class="authored">
															Authored by
															{{
																getPerson(
																	checklist.owner
																)
															}}
														</p>
													</div>
												</div>
												<fa
													v-if="
														access ||
														userManagesEmployee
													"
													class="icon btn btn-icon"
													icon="arrow-right"
													@click="
														viewChecklist(
															checklist.id
														)
													"
												/>
											</div>
										</div>
									</div>
								</div>
								<div class="vertical-center muted" v-else>
									<fa
										icon="tasks"
										style="
											font-size: 25px;
											margin-bottom: 5px;
										"
									/>
									<h3>No checklists found</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<transition name="fade" mode="out-in">
		<router-view></router-view>
	</transition>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import getDocument from "../../composables/getDocument";
import { computed, onMounted, watchEffect } from "@vue/runtime-core";
import Alert from "@/components/Alert";
import UpdateProfileForm from "../../components/people/UpdateProfileForm";
import { projectFirestore } from "../../firebase/config";
import PageLoading from "../../components/PageLoading.vue";
import account from "../../composables/account";
import Documents from "../../components/Documents.vue";
import { useMeta } from "vue-meta";
import ChecklistList from "../../components/checklists/ChecklistList.vue";
import checklistCollection from "../../composables/checklistcollection";
import moment from "moment";
import employeeService from "../../composables/employeeService";

export default {
	name: "UpdateProfile",
	components: {
		Alert,
		UpdateProfileForm,
		PageLoading,
		Documents,
		ChecklistList,
	},
	props: ["id"],
	setup(props) {
		useMeta({ title: "Profile" });
		const error = ref(null);
		const { user, companyId, userDoc, role } = account();
		const employeeId = ref(props.id);
		const router = useRouter();
		const profile = ref(true);
		const peopleId = props.id;
		const { checklists, getChecklists } = checklistCollection();
		const { fetchEmployeeNames, fetchInspector } = employeeService();
		// Data
		let db = projectFirestore;

		let collectionRef = db
			.collection("companies")
			.doc(companyId)
			.collection("employees");

		// Get realtime employee data
		let employeeRef = collectionRef.doc(props.id);

		const { error: getDocErr, document: employee } =
			getDocument(employeeRef);

		const access = computed(() => {
			if (role.value == "Admin" || user.value.uid == props.id) {
				return true;
			}
		});

		const userManagesEmployee = ref(false);
		const maskedEmail = ref(null);
		const showEmail = ref(false);
		const ownerEmployees = ref([]);
		const inspectorEmployees = ref([]);

		const getPerson = (id) => {
			const employee = ownerEmployees.value.find((emp) => emp.id === id);
			if (employee) {
				return employee.name;
			} else {
				const inspector = inspectorEmployees.value.find(
					(emp) => emp.id === id
				);
				if (inspector) {
					return inspector.name;
				} else {
					return "";
				}
			}
		};
		watchEffect(() => {
			if (userDoc.value && employee.value) {
				let employeeEmail = employee.value.profile.email;
				const maskChars = 2;

				const atPosition = employeeEmail.indexOf("@");
				const username = employeeEmail.substring(0, atPosition);
				const domain = employeeEmail.substring(atPosition);

				const maskedUsername =
					username.substring(0, maskChars) +
					"*".repeat(username.length - maskChars);

				maskedEmail.value = maskedUsername + domain;

				const userManages = userDoc.value.manages;
				const employeeProfileLocations = employee.value.locations;

				for (const id of userManages) {
					if (employeeProfileLocations.includes(id)) {
						userManagesEmployee.value = true;
						showEmail.value = true;
						return;
					}
				}
			}
		});

		watchEffect(async () => {
			if (userDoc.value) {
				await getChecklists(
					user.value.uid,
					userDoc.value.role,
					companyId,
					"complete",
					null,
					null,
					[],
					true,
					false,
					null,
					peopleId,
					false
				);
			}
		});

		const showForm = (id, manages) => {
			router.push({
				name: "UpdateProfileForm",
				query: { id: id, manages: manages },
			});
		};

		const handleClick = () => {
			router.push({ name: "PeopleDash" });
		};

		// handle errors
		watchEffect(() => {
			if (getDocErr.value) {
				error.value = "Cannot fetch data";
			} else {
				error.value = null;
			}
		});

		const viewChecklists = ref(false);

		const showChecklists = () => {
			if (viewChecklists.value) {
				viewChecklists.value = false;
			} else {
				viewChecklists.value = true;
			}
		};

		const viewChecklist = (id) => {
			let routeData = router.resolve({
				name: "ChecklistDetail",
				params: { id: id },
			});
			window.open(routeData.href, "_blank");
		};
		onMounted(async () => {
			const ownerEmployeeNames = await fetchEmployeeNames(companyId);
			ownerEmployeeNames.docs.forEach((doc) => {
				const employeeId = doc.id;

				const employeeFullName = doc.data().profile.fullName;

				ownerEmployees.value.push({
					id: employeeId,
					name: employeeFullName,
				});
			});
			const inspectorNames = await fetchInspector(companyId);
			inspectorNames.forEach((doc) => {
				const inspectorId = doc.id;

				const inspectorName = doc.name;

				inspectorEmployees.value.push({
					id: inspectorId,
					name: inspectorName,
				});
			});
		});
		return {
			error,
			employee,
			handleClick,
			access,
			showForm,
			profile,
			employeeId,
			companyId,
			userDoc,
			peopleId,
			viewChecklists,
			showChecklists,
			checklists,
			viewChecklist,
			moment,
			role,
			user,
			userManagesEmployee,
			maskedEmail,
			showEmail,
			getPerson,
		};
	},
};
</script>

<style lang="scss" scoped>
.page-container {
	//padding: 1em 0 1em 1em;
	padding: 1em;
	@include respond(large) {
		padding: 1em 0 1em 1em;
	}
}
.authored {
	font-size: 0.8em;
	color: $rm-dark-grey;
}
.employee-profile-form {
	width: 40%;
	text-align: center;
}
#profileImage {
	width: 80px;
	height: 80px;
	margin: 0 auto;
}
.table-row-shimmer {
	height: 100px;
	border-radius: 0.5em;
	width: 100%;
}
.lines {
	height: 1.2em;
	width: 100px;
	border-radius: 6px;
	margin-left: 1em;
}
.profile-tile {
	width: 40%;
	height: fit-content;
	text-align: center;
}
.detail-left {
	max-width: 400px;
	width: 100% !important;
}
.detail-title {
	width: 350px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#update-profile {
	padding: 0;
	border-radius: 0;
	box-shadow: none;
}

#cancel {
	align-items: center;
}

#preview {
	width: 80px;
	height: 80px;
	margin: 0 auto;
}
.right-content {
	span {
		//font-size: 18px !important;
		//  font-size: 16px !important;

		@include respond(small) {
			//      font-size: 18px !important;
		}
	}
	.page-content {
		left: 0 !important;
		top: 140px !important;

		@include respond(medium) {
			left: 210px !important;
			top: 70px !important;
		}
		@include respond(large) {
			overflow: hidden !important;
		}

		.split {
			flex-direction: column !important;

			@include respond(small) {
				flex-direction: row !important;
			}
		}
	}
}
.collection-title {
	@include respond(large) {
		padding: 0 1em 0.8em;
	}
}
/*.back:first-of-type {
    position: relative;
    padding-right: 10px;
  }
  .back:first-of-type::after {
    content: '/';
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%) rotate(0deg);
  } */
.flex h2 {
	font-size: 16px;

	@include respond(small) {
		font-size: 18px;
	}
	@include respond(large) {
		font-size: 22px;
	}
}
</style>
